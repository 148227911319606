import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-goal-modal',
  templateUrl: './create-goal-modal.component.html',
  styleUrls: ['./create-goal-modal.component.scss']
})
export class CreateGoalModalComponent implements OnInit {
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.showLabels = false;
    this.showStartDateContainer = false;
    this.showDueDateContainer = false;
    this.openDeleteConfirmation = false;

    if (!this.createMoreMilestones) {
      this.clearEmptyMilestones();
    }
  }
  @ViewChild('titleInput', {static: false}) titleInput: ElementRef | undefined;
  
  @Input() set openGoalModal(value: any) {
    if (value.open) {
      switch (value.mode) {
        case 'create':
          this.createMode = true;
          this.goalMilestones.length = 0;
          break;
        case 'preview':
          this.setPreviewMode(value.goal);
          break;
        case 'leader-preview':
          this.setLeaderPreviewMode(value.goal);
      }
      this.openModal();
    } else {
      if (value.mode === 'fromEdit') {
        this.setPreviewModeAfterEdit(value.goal);
      }
    }
  }
  
  @Input() set goalAvailableLabels(value: any) {
    if (value) {
      this.availableLabels = value;
    }
  }

  @Input() set goalAvailableFrequencies(value: any) {
    this.defaultGoalFrequencies = value.map((item: any) => ({ ...item }));
    this.setupFrequencyTitle();
  }
  
  @Output() goalCreated = new EventEmitter<any>();
  @Output() goalStarted = new EventEmitter<any>();
  @Output() goalCompleted = new EventEmitter<any>();
  @Output() goalArchived = new EventEmitter<any>();
  @Output() goalEdited = new EventEmitter<any>();
  @Output() goalDeleted = new EventEmitter<any>();
  @Output() saveMilestones = new EventEmitter<any>();
  @Output() updateMilestone = new EventEmitter<any>();
  @Output() goalMoveToIdea = new EventEmitter<any>();
  
  goalForm: FormGroup;
  
  showLabels: boolean = false;
  createMoreMilestones: boolean = true;
  isEditMilestone: boolean = false;
  showDueDateContainer: boolean = false;
  showStartDateContainer: boolean = false;
  sumbitedDueDate: boolean = false;
  sumbitedStartDate: boolean = false;
  createMode: boolean = false;
  previewMode: boolean = false;
  leaderPreviewMode: boolean = false;
  editGoalMode: boolean = false;
  disableGoalCreationBtns: boolean = true;
  openDeleteConfirmation: boolean = false;
  showLoadingAfterSaveChanges: boolean = false;
  danishVersion: boolean = false;

  goalMilestones: any[] = [];
  goalLabels: any[] = [];
  availableLabels: any[] = [];
  goalPrivacy: any[] = [
    {title:this.translate.instant('my_plan_goal_modal_visible_option'), isVisible: true},
    {title:this.translate.instant('my_plan_goal_modal_private_option'), isVisible: false}];
  goalFrequencies: any[] = [];
  defaultGoalFrequencies: any[] = [];

  goalEndDate: any;
  goalStartDate: any;
  lastEditedMilestoneValue: any;
  visibility: any;
  frequencie: any;
  goalToPreview: any;
  minStartDateValue: any;
  minDueDateValue: any;
  goalFrequencyForPreview: any;

  indexOfActiveMilestoneInput: number = 0;
  milestoneProgressBarValue: number = 0;

  constructor(private formBuilder: FormBuilder, private translate: TranslateService) {
    this.minStartDateValue = new Date();
    this.goalForm = this.formBuilder.group({
      title: new FormControl('', [Validators.maxLength(100)]),
      description: new FormControl('')
    });

    this.goalForm.get('title')?.valueChanges.subscribe((value: any) => {
      if (value.length) {
        this.disableGoalCreationBtns = false;
        if (value.length === 101) {
          this.titleInput?.nativeElement.blur();
        }
      } else {
        this.disableGoalCreationBtns = true;
      }
    }); 
  }

  ngOnInit(): void {
    if (this.previewMode && this.goalToPreview.frequency) {
      this.setFrequencyForPreviewMode();
    }
    //@ts-ignore
    this.danishVersion = JSON.parse(localStorage.getItem('language')).tag.includes('da');
  }

  createIdeaGoal(start: any) {
    let labelsToAttach = this.goalLabels.map((label: any) => label.uuid);
    this.goalCreated.emit({
      goalBody: {
        title: this.goalForm.value.title,
        description: this.goalForm.value.description,
        dueDate: (this.goalEndDate && this.goalEndDate !== undefined) ? formatDate(this.goalEndDate,'dd.MM.yyyy', 'en-US'):'',
        startDate: (this.goalStartDate && this.goalStartDate !== undefined) ? formatDate(this.goalStartDate,'dd.MM.yyyy', 'en-US'):'',
        isVisible: this.visibility.isVisible,
        frequencyUuid: this.frequencie.uuid ? this.frequencie.uuid:null,
        labels: labelsToAttach,
      },
      milestones: this.goalMilestones,
      start: start
    });
  }

  startIdeaGoal() {
    if (this.frequencie.slug !== this.goalToPreview.frequency?.slug) {
      this.goalToPreview.changedFrequency = this.frequencie.uuid ? this.frequencie.uuid : null;
    }
    if (this.goalToPreview.dueDate) {
      if (this.goalToPreview.dueDate?.date !== this.goalEndDate && (this.goalEndDate !== null || this.goalEndDate !== undefined)) {
        this.goalToPreview.changedDueDate = formatDate(this.goalEndDate,'dd.MM.yyyy', 'en-US');
      }
    } else if(this.goalToPreview.dueDate === null) {
      if (this.goalEndDate !== null) {
        this.goalToPreview.changedDueDate = formatDate(this.goalEndDate,'dd.MM.yyyy', 'en-US');
      }
    }
    if (this.goalToPreview.startDate) {
      if (this.goalToPreview.startDate?.date !== this.goalStartDate && (this.goalStartDate !== null || this.goalStartDate !== undefined)) {
        this.goalToPreview.changedStartDate = formatDate(this.goalStartDate,'dd.MM.yyyy', 'en-US');
      }
    } else if(this.goalToPreview.startDate === null) {
      if (this.goalStartDate !== null) {
        this.goalToPreview.changedStartDate = formatDate(this.goalStartDate,'dd.MM.yyyy', 'en-US');
      }
    }
    this.goalStarted.emit({goal: this.goalToPreview});
  }

  moveToDone() {
    this.goalCompleted.emit({goal: this.goalToPreview});
  }

  moveToArchive() {
    this.goalArchived.emit(this.goalToPreview);
  }

  moveToIdea() {
    this.editGoalMode = false;
    this.goalMoveToIdea.emit(this.goalToPreview);
  }

  createEmptyMilestone() {
    this.goalMilestones.push({isChecked: false, description: '', index: this.goalMilestones.length});
    this.createMoreMilestones = false;
  }

  clearEmptyMilestones() {
    this.goalMilestones = this.goalMilestones.filter((milestone: any) => milestone.description !== '');
    this.createMoreMilestones = true;
  }

  saveMilestone(milestone: any, index: number) {
    if (milestone.description !== '') {
      this.goalMilestones.map((mile) => {
        if (mile.index === index && milestone.description !== '') {
          mile.description = milestone.description;
        }
      });
      if ((this.previewMode || this.editGoalMode) && !this.isEditMilestone) {
        this.saveMilestones.emit({milestone: milestone, goal: this.goalToPreview});
      }
      if ((this.previewMode || this.editGoalMode) && this.isEditMilestone) {
        this.updateMilestone.emit({uuid: milestone.uuid, body: {description: milestone.description}});
      }
      this.getProgressBarValue();
      this.createEmptyMilestone();
    } else {
      this.goalMilestones.splice(index, 1);
    }
    this.isEditMilestone = false;
  }

  editMilestone(event: any, index: number) {
    this.lastEditedMilestoneValue = event.target.value;
    this.indexOfActiveMilestoneInput = index;
    this.isEditMilestone = true;
  }

  cancelMilestone(index: number, milestoneToChange: string) {
    if (!this.isEditMilestone) {
      this.goalMilestones = this.goalMilestones.filter((milestone) => milestone.index !== index);
    } else if (this.isEditMilestone) {      
      this.goalMilestones[this.indexOfActiveMilestoneInput].description = this.lastEditedMilestoneValue;
      this.isEditMilestone = false;
    }      
    this.createMoreMilestones = true;
  }

  addLabel(event: any, index: number) {
    if (event) {
      this.goalLabels.push(this.availableLabels[index]);
    } else {
      this.goalLabels = this.goalLabels.filter((label) => label.title !== this.availableLabels[index].title);
    }
  }

  clearLabels() {
    this.availableLabels.forEach((label: any) => {
      label.isChecked = false;
    });
  }

  clearFrequencies() {
    this.goalFrequencies.length = 0;
  }

  clearMilestones() {
    this.goalMilestones = [];  
  }
  
  showStartDate(event: any) {
    event?.stopPropagation();
    this.showStartDateContainer = true;
  }

  showDueDate(event: any) {
    event?.stopPropagation();
    this.showDueDateContainer = true;
  }

  hideStartDate() {
    event?.stopPropagation();
    this.showStartDateContainer = false;
  }

  hideDueDate() {
    event?.stopPropagation();
    this.showDueDateContainer = false;
  }

  submitStartDateChanges() {
    if (this.goalStartDate !== null) {
      if (this.goalEndDate && (new Date(this.goalStartDate) > new Date(this.goalEndDate))) {
        this.goalEndDate = null;
        this.sumbitedDueDate = false;
      }
      this.sumbitedStartDate = true;
      this.minDueDateValue = this.goalStartDate;
      this.goalFrequencies = [];
      this.setupFrequencyTitle();
      if (!this.createMode && this.goalToPreview.frequency) {
        this.setFrequencyForPreviewMode();
      }
      this.hideStartDate();
    }
  }

  submitDateChanges() {
    if (this.goalEndDate !== null) {
      this.sumbitedDueDate = true;
      this.hideDueDate();
    }
  }

  cancelDateOptions() {
    event?.stopPropagation();
    this.clearDateOptions();
    this.showStartDateContainer = false;
  }

  editMode() {
    this.goalForm.controls['title'].setValue(this.goalToPreview.title);
    this.goalForm.controls['description'].setValue(this.goalToPreview.description);
    this.goalEndDate = this.goalToPreview.dueDate ? new Date(this.goalToPreview.dueDate.date) : null;
    this.goalStartDate = this.goalToPreview.startDate ? new Date(this.goalToPreview.startDate.date) : null;
    this.sumbitedDueDate = this.goalToPreview.dueDate ? true:false;
    this.sumbitedStartDate = this.goalToPreview.startDate ? true:false;
    this.goalToPreview.labels.forEach((label:any) => {
      this.goalLabels.push(label.label);
      this.availableLabels.map((availableLab: any) => {
        if (availableLab.uuid === label.label.uuid) {
          availableLab.isChecked = true;
        }
      })
    });
    this.setupFrequencyTitle();
    this.setVisibilityForEditMode(this.goalToPreview.isVisible);
    if (this.goalToPreview.frequency) {
      this.setFrequencyForPreviewMode();
    }
    this.createMode = false;
    this.previewMode = false;
    this.editGoalMode = true;
  }

  saveGoalChanges() {
    let labelsToAttach = this.goalLabels.map((label: any) => label.uuid);
    this.goalEdited.emit({
      goalBody: {
        title: this.goalForm.value.title,
        description: this.goalForm.value.description,
        dueDate: (this.goalEndDate && this.goalEndDate !== undefined) ? formatDate(this.goalEndDate,'dd.MM.yyyy', 'en-US'):null,
        startDate: (this.goalStartDate && this.goalStartDate !== undefined) ? formatDate(this.goalStartDate,'dd.MM.yyyy', 'en-US'):null,
        isVisible: this.visibility.isVisible,
        frequencyUuid: this.frequencie.uuid,
        labels: labelsToAttach,
        status: this.goalToPreview.status
      },
      goalUuid: this.goalToPreview.uuid,
      goalEdited: true
    });
    this.goalLabels = [];
    this.editGoalMode = false;
    this.showLoadingAfterSaveChanges = true;
  }

  setupFrequencyTitle() {
    this.goalFrequencies = this.defaultGoalFrequencies.map((item: any) => ({ ...item }));
    this.goalFrequencies.map((freq: any) => {
      if (freq.slug !== 'not_repeat') {
        if (freq.slug === 'weekly') {
          freq.name = freq.name +' '+ (this.goalStartDate !== null ? new Date(this.goalStartDate).toLocaleDateString('en-US', { weekday: 'long' }) : this.goalToPreview?.startDate ? new Date(this.goalToPreview.startDate.date).toLocaleDateString('en-US', { weekday: 'long' }) : '');
        } else if (freq.slug === 'montly') {
          freq.name = freq.name +' '+ (this.goalStartDate !== null ? new Date(this.goalStartDate).getDate()+'th' : this.goalToPreview?.startDate ? new Date(this.goalToPreview.startDate.date).getDate()+'th' : '');
        } else if (freq.slug === 'annually') {
          freq.name = freq.name+' '+ (this.goalStartDate !== null ? formatDate(this.goalStartDate,'dd MMM', 'en-US') : this.goalToPreview?.startDate ? formatDate(this.goalToPreview?.startDate.date,'dd MMM', 'en-US') : '');
        }
      }
    });
    if (this.previewMode && this.goalToPreview.frequency  && this.goalToPreview.status !== 'to_do') {
      this.goalFrequencyForPreview = this.goalFrequencies.filter((freq: any) => freq.uuid === this.goalToPreview.frequency.uuid)[0].name;
    }
  }

  setFrequencyForPreviewMode() {
    let previewFreq = this.goalFrequencies.filter((freq: any) => freq.slug === this.goalToPreview.frequency.slug)[0];
    this.goalFrequencies = this.goalFrequencies.filter((freq: any) => freq.slug !== this.goalToPreview.frequency.slug);
    this.goalFrequencies.unshift(previewFreq);
    this.frequencie = this.goalToPreview.frequency;
  }

  setVisibilityForEditMode(goalVisibility: any) {
    this.goalPrivacy.forEach((visibility: any) => {
      this.visibility = goalVisibility === visibility.isVisible ? visibility : '';
    })
  }

  setPreviewMode(goalToPreview: any) {
    this.previewMode = true;
    this.goalToPreview = goalToPreview;
    this.goalMilestones = goalToPreview.milestones;
    this.goalStartDate = goalToPreview.startDate ? new Date(goalToPreview.startDate.date) : null;
    this.getProgressBarValue();
    if (this.goalToPreview.status === 'to_do') {
      this.goalEndDate = goalToPreview.dueDate ? new Date(goalToPreview.dueDate.date) : null;
      this.minDueDateValue = goalToPreview.startDate ? new Date(goalToPreview.startDate.date) : new Date();
      this.sumbitedDueDate = goalToPreview.dueDate ? true:false;
      this.sumbitedStartDate = goalToPreview.startDate ? true:false;
    }
  }

  setLeaderPreviewMode(goalToPreview: any) {
    this.leaderPreviewMode = true;
    this.goalToPreview = goalToPreview;
    this.goalMilestones = goalToPreview.milestones;
    this.getProgressBarValue();
  }

  setPreviewModeAfterEdit(goalToPreview: any) {
    this.previewMode = true;
    this.goalToPreview = goalToPreview;
    this.showLoadingAfterSaveChanges = false;
  }

  clearDateOptions() {
    this.goalEndDate = '';
    this.sumbitedDueDate = false;
  }
  
  milestoneCheckChanged(milestone: any) {
    if (this.previewMode || this.editGoalMode) {
      this.updateMilestone.emit({uuid: milestone.uuid, body: {isChecked: milestone.isChecked}});
    }
    this.getProgressBarValue();
  }

  getProgressBarValue() {
    if (this.goalMilestones.length) {
      this.milestoneProgressBarValue = Math.round((this.goalMilestones.filter((milestone: any) => milestone.isChecked).length / this.goalMilestones.length) * 100);
    }
  }

  openModal() {
    const openModal = document.getElementById('goalModalButton');
    openModal?.click();
  }

  closeModal() {
    this.clearLabels();
    this.clearFrequencies();
    this.clearMilestones();
    this.goalToPreview = null;
    this.goalCreated.emit(false);
  }

  openLabels() {
    this.showLabels = !this.showLabels;
  }

  deleteGoal() {
    this.goalDeleted.emit(this.goalToPreview);
    this.previewMode = false;
  }

  openConfirmationModalForDelete() {
    this.openDeleteConfirmation = true;
  }

  closeConfirmationModalForDelete() {
    this.openDeleteConfirmation = false;
  }

}
