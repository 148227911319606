<div class="one-on-one-main-container">
    <div class="one-on-one-search-container">
        <div class="search-input-container">
            <i class="bi bi-search search-icon"></i>
            <input [appAutofocus]="true" [(ngModel)]="searchValue" (ngModelChange)="this.searching.next($event)" class="search-input" type="text" placeholder="{{'my_plan_one_on_one_search_placeholder' | translate}}">
        </div>
    </div>
    <div class="spinner-border text-primary categ-statement-spinner" role="status" *ngIf="showMembersLoading"></div>
    <div class="members-container">
        <div class="member-card" (click)="memberChosed(member)" *ngFor="let member of membersList">
            <img class="member-card-icon" [src]="(member?.avatar?.path !== null) ? this.data.baseUrl+member.avatar.path : '../../../assets/svg/user-icon.svg'">
            <h2 class="member-card-name">{{member.detail.firstname +' '+member.detail.lastname}}</h2>
            <span class="member-card-email" pTooltip="{{member.email}}" tooltipPosition="bottom" tooltipStyleClass="member-email-tooltip">{{member.email}}</span>
        </div>
    </div>
</div>
