<button type="button" id="goalModalButton" class="btn btn-primary" data-bs-toggle="modal" hidden
        data-bs-target="#goalModal">
</button>  
  <!-- Modal -->
<div class="modal fade" id="goalModal" tabindex="-1" data-bs-backdrop ="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content content-box">
        <div [ngStyle]="previewMode || leaderPreviewMode ? {'padding':'3.2rem 3.2rem 0 3.2rem'}:{}" class="modal-header goal-header">
            <div *ngIf="previewMode || leaderPreviewMode" [ngClass]="goalToPreview.status === 'to_do' ? 'ideas': goalToPreview.status === 'in_progress' ? 'in-progress': goalToPreview.status === 'done' ? 'done':''" class="goal-status-container">
              <span>{{goalToPreview.status === 'to_do' ? ('my_plan_goals_overview_ideas' | translate) :
                      goalToPreview.status === 'in_progress' ? ('my_plan_goals_overview_in_progress_header' | translate) :
                      goalToPreview.status === 'done' ? ('my_plan_goals_overview_completed' | translate):''}}</span>
            </div>
            <h2 *ngIf="createMode || editGoalMode">{{createMode ? ('my_plan_goal_modal_title' | translate) : ('my_plan_goal_modal_edit_title' | translate)}}</h2>
            <i [ngStyle]="showLoadingAfterSaveChanges ? {'margin-left':'auto'}:{}" class="bi bi-x-lg close-btn" (click)="closeModal()" id="closeModal" alt="X" data-bs-dismiss="modal"
            aria-label="Close"></i>
        </div>
        <div [ngStyle]="showLoadingAfterSaveChanges ? {'display':'flex'}:{}" class="modal-body goal-body">
          <div *ngIf="previewMode || leaderPreviewMode" class="goal-preview-header">
            <img src="../../../../../assets/svg/goal_preview_title_icon.svg">
            <h2 class="goal-preview-title">{{goalToPreview.title}}</h2>
            <div *ngIf="goalToPreview.dueDate && goalToPreview.status === 'in_progress'" class="goal-preview-due-date-con">
              <span class="goal-preview-due-date">{{('my_plan_goal_modal_preview_deadline' | translate :{date: goalToPreview.dueDate.date | date:'dd MMM yyyy'})}}</span>
            </div>
            <div *ngIf="goalToPreview.completionDate && goalToPreview.status === 'done'" class="goal-preview-due-date-con">
              <span class="goal-preview-due-date">{{('my_plan_goal_modal_preview_deadline_completed' | translate :{date: (goalToPreview.completionDate.created.date | date:'dd MMM yyyy')})}}</span>
            </div>
            <div *ngIf="goalToPreview.frequency && (goalToPreview.startDate || goalToPreview.frequency.slug === 'daily') && goalToPreview.status !== 'to_do'" class="goal-preview-frequencie-con">
              <span>{{goalToPreview.frequency.slug === 'daily' ? goalToPreview.frequency.name:goalFrequencyForPreview}}</span>
            </div>
            <div class="goal-preview-visibility-con">
              <img *ngIf="!goalToPreview.isVisible" src="../../../../../assets/svg/goal_visibility_off.svg">
              <img *ngIf="goalToPreview.isVisible" src="../../../../../assets/svg/goal_visibility.svg">
            </div>
          </div>
          <span *ngIf="previewMode || leaderPreviewMode" class="goal-preview-description" [innerHTML]="goalToPreview.description"></span>
          <form *ngIf="createMode || editGoalMode" [formGroup]="goalForm">
              <input #titleInput [ngClass]="goalForm.get('title')?.hasError('maxlength') ? 'error':''" maxlength="101" class="goal-input title" formControlName="title" placeholder="{{'my_plan_goal_modal_title_placeholder' | translate}}">
              <span *ngIf="goalForm.get('title')?.hasError('maxlength')" class="title-input-error">Max 100 characters!</span>
              <textarea class="goal-input description" formControlName="description" placeholder="{{'my_plan_goal_modal_description_placeholder' | translate}}"></textarea>  
          </form>
          <div *ngIf="!showLoadingAfterSaveChanges" class="milestones-container">
            <div [ngClass]="this.milestoneProgressBarValue ? 'has-value':''" class="progress-bar-container">
              <span class="percentage-value">{{this?.milestoneProgressBarValue}}%</span>
              <p-progressBar [ngStyle]="{'width':'100%'}" [value]="this.milestoneProgressBarValue" [showValue]="false"></p-progressBar>
            </div>
            <div class="milestone-column" *ngFor="let milestone of goalMilestones, let index = index, let last = last">
              <div class="milestone-input-container">
                <input 
                  [(ngModel)]="milestone.isChecked" 
                  (ngModelChange)="milestoneCheckChanged(milestone)" 
                  [ngStyle]="leaderPreviewMode ? {'cursor':'default'}:{}" 
                  class="checkbox milestone" 
                  type="checkbox" 
                  [disabled]="leaderPreviewMode">
                <input #milestoneInput [appAutofocus]="!createMoreMilestones"
                  (keyup.enter)="saveMilestone(milestone, index);milestoneInput.blur()" 
                  [(ngModel)]="milestone.description"
                  [ngStyle]="leaderPreviewMode ? {'cursor':'default'}:{}" class="milestone-input-title" 
                  (click)="!leaderPreviewMode ? editMilestone($event, index):''" 
                  placeholder="{{'my_plan_goal_modal_define_milestone' | translate}}" 
                  [readOnly]="leaderPreviewMode">
              </div>
              <div *ngIf="(!createMoreMilestones && last) || (isEditMilestone && indexOfActiveMilestoneInput === index)" class="milestone-action-container">
                <button pButton (click)="saveMilestone(milestone, index)" class="milestone-action-btn save" label="{{'save_button' | translate}}"></button>
                <button pButton (click)="cancelMilestone(index, milestone.description)" class="milestone-action-btn cancel" label="{{'cancel_button' | translate}}"></button>
              </div>
            </div>  
            <button *ngIf="!leaderPreviewMode" pButton class="add-milestone-button" (click)="createEmptyMilestone();$event.stopPropagation()" [disabled]="!createMoreMilestones" label="{{'my_plan_goal_modal_add_milestone_btn' | translate}}"></button>
          </div>
          <div *ngIf="createMode || editGoalMode" class="labels-container">
            <div class="label-and-questionmark">
              <span class="labels-btn-label">{{'my_plan_goal_modal_labels' | translate}}</span>
              <div class="w-dl-questionmark-container">
                <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                <div [ngStyle]="danishVersion ? {'left':'6.2rem'}:{}" class="workspace-dl-tooltip label">{{'my_plan_goal_modal_labels_question_mark'|translate}}</div>
              </div>
            </div>
            <div class="selected-labels-container">
              <div *ngFor="let label of goalLabels" class="selected-label">
                <span [ngStyle]="{'background-color':label.backgroundColor, 'color':label.textColor}" class="label-title selected">{{label.title}}</span>
              </div>
              <div class="attach-labels-btn-container">
                <button pButton class="labels-btn" (click)="openLabels();$event.stopPropagation()" label="{{'my_plan_goal_modal_attach_label_btn' | translate}}"></button>
                <div class="pick-label-container" [ngStyle]="showLabels ? {'display':'flex'}:{}">
                  <div *ngFor="let labelToChose of availableLabels, let index = index" class="label">
                    <input [(ngModel)]="labelToChose.isChecked" (ngModelChange)="addLabel($event, index)" class="checkbox labels" type="checkbox" (click)="$event.stopPropagation()">
                    <span [ngStyle]="{'background-color':labelToChose.backgroundColor, 'color':labelToChose.textColor}" 
                          class="label-title"
                          pTooltip="{{labelToChose.description}}" tooltipPosition="bottom" tooltipStyleClass="response-distribution-tooltip">{{labelToChose.title}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(createMode || goalToPreview.status === 'to_do' || editGoalMode) && !leaderPreviewMode && !showLoadingAfterSaveChanges" class="dates-and-other-settings">
            <div *ngIf="createMode || editGoalMode" class="setting-row">
              <div class="visibility-and-questionmark">
                <span class="labels-btn-label">{{'my_plan_goal_modal_visibility_label' | translate}}</span>
                <div class="w-dl-questionmark-container">
                  <i class="bi bi-question-circle-fill me-1 text-warning w-dl-questionmark"></i>
                  <div [ngStyle]="danishVersion ? {'left':'7.3rem'}:{}" class="workspace-dl-tooltip visibility">{{'my_plan_goal_modal_visibility_label_question_mark'|translate}}</div>
                </div>
              </div>
              <p-dropdown [(ngModel)]="visibility" [options]="goalPrivacy" optionLabel="title" dropdownIcon="bi bi-caret-down-fill">
                <ng-template pTemplate="selectedItem">
                  <div class="visibility-item">
                    <img *ngIf="!visibility?.isVisible" src="../../../../../assets/svg/goal_visibility_off.svg">
                    <img *ngIf="visibility?.isVisible" src="../../../../../assets/svg/goal_visibility.svg">
                    <span>{{visibility?.title}}</span>
                  </div>
                </ng-template>
                <ng-template let-visibility pTemplate="item">
                  <div class="visibility-item">
                    <img *ngIf="!visibility.isVisible" src="../../../../../assets/svg/goal_visibility_off.svg">
                    <img *ngIf="visibility.isVisible" src="../../../../../assets/svg/goal_visibility.svg">
                    <span>{{visibility.title}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div *ngIf="createMode || (previewMode && goalToPreview.status === 'to_do') || editGoalMode" class="setting-row">
              <span class="labels-btn-label">{{'my_plan_goal_modal_start_date_label' | translate}}</span>
              <div class="start-date-input-container" (click)="showStartDate($event)">
                <span *ngIf="!sumbitedStartDate">-</span>
                <span *ngIf="sumbitedStartDate">{{goalStartDate | date:'dd-MM-yyyy'}}</span>
                <img class="calendar-icon" src="../../../../../assets/svg/m_info_icon_calendar.svg">
                <div class="calendar-container" [ngStyle]="showStartDateContainer ? {'display':'flex'}:{}">
                  <p-calendar [(ngModel)]="goalStartDate" (ngModelChange)="submitStartDateChanges()" [minDate]="minStartDateValue" [inline]="true"></p-calendar>
                </div>
              </div>
            </div>
            <div *ngIf="createMode || (previewMode && goalToPreview.status === 'to_do') || editGoalMode" class="setting-row">
              <span class="labels-btn-label">{{'my_plan_goal_modal_repeating_label' | translate}}</span>
              <p-dropdown [(ngModel)]="frequencie" [options]="goalFrequencies" optionLabel="name" dropdownIcon="bi bi-caret-down-fill">
                <ng-template pTemplate="selectedItem">
                  <div class="visibility-item">
                    <span>{{frequencie?.name}}</span>
                  </div>
                </ng-template>
                <ng-template let-freq pTemplate="item">
                  <div class="visibility-item">
                    <span>{{freq.name}}</span>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
            <div *ngIf="createMode || (previewMode && goalToPreview.status === 'to_do') || editGoalMode" class="setting-row">
              <span class="labels-btn-label">{{'my_plan_goal_modal_deadline_label' | translate}}</span>
              <div class="start-date-input-container" (click)="showDueDate($event)">
                <span *ngIf="!sumbitedDueDate">-</span>
                <span *ngIf="sumbitedDueDate">{{goalEndDate | date:'dd-MM-yyyy'}}</span>
                <img class="calendar-icon" src="../../../../../assets/svg/m_info_icon_calendar.svg">
                <div class="calendar-container" [ngStyle]="showDueDateContainer ? {'display':'flex'}:{}">
                  <p-calendar [(ngModel)]="goalEndDate" (ngModelChange)="submitDateChanges()" [minDate]="minDueDateValue" [inline]="true"></p-calendar>
                  <!-- <div class="calendar-footer-container">
                    <span (click)="clearDateOptions()">Clear</span>
                    <span (click)="cancelDateOptions()" style="margin-left:auto">Cancel</span>
                    <span (click)="submitDateChanges()">Ok</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border text-primary categ-statement-spinner" role="status" *ngIf="showLoadingAfterSaveChanges"></div>
        </div>
        <div class="modal-footer goal-footer">
          <div *ngIf="previewMode || leaderPreviewMode" [ngStyle]="leaderPreviewMode ? {'max-width':'unset'}:{}" class="goal-preview-labels-container">
            <div [ngStyle]="{'background-color':label.label.backgroundColor, 'color':label.label.textColor}" class="goal-preview-label" *ngFor="let label of goalToPreview.labels">
              <span>{{label.label.title}}</span>
            </div>
          </div>
          <button *ngIf="createMode || editGoalMode && !leaderPreviewMode" pButton [ngStyle]="editGoalMode ? {'margin-right':'0'}:{}" class="goal-footer-btn cancel" (click)="closeModal()" data-bs-dismiss="modal" label="{{'cancel_button' | translate}}"></button>
          <div *ngIf="previewMode && !editGoalMode && !leaderPreviewMode" class="delete-btn-container" (click)="openConfirmationModalForDelete();$event.stopPropagation()">
            <img src="../../../../../assets/svg/icon-delete.svg">
          </div>
          <div *ngIf="openDeleteConfirmation" (click)="$event.stopPropagation()" class="delete-goal-modal-container">
            <span>{{'my_plan_goal_modal_delete_modal_text' | translate}}</span>
            <div class="modal-buttons-container">
                <button class="modal-button no" pButton label="No" (click)="closeConfirmationModalForDelete()"></button>
                <button class="modal-button yes" pButton label="Yes" data-bs-dismiss="modal" (click)="deleteGoal()"></button>
            </div>
         </div>
          <button *ngIf="previewMode && !leaderPreviewMode && goalToPreview.status !== 'done'" pButton class="goal-footer-btn edit" (click)="editMode()" label="{{'my_plan_goal_modal_edit_goal_btn' | translate}}"></button>
          <button *ngIf="createMode && !previewMode && !leaderPreviewMode" [disabled]="disableGoalCreationBtns" pButton class="goal-footer-btn keep-as-idea" (click)="createIdeaGoal(false)" data-bs-dismiss="modal" label="{{'my_plan_goal_modal_keep_as_idea_btn' | translate}}"></button>
          <button *ngIf="editGoalMode && goalToPreview.status === 'in_progress'" 
            pButton 
            class="goal-footer-btn keep-as-idea" 
            (click)="moveToIdea()" 
            data-bs-dismiss="modal" 
            label="{{'my_plan_goal_modal_move_to_idea_btn' | translate}}"
            pTooltip="{{'my_plan_goal_modal_move_to_idea_btn_tooltip' | translate}}" [escape]="false" tooltipPosition="top" tooltipStyleClass="response-distribution-tooltip"></button>
          <button *ngIf="createMode" [disabled]="disableGoalCreationBtns" pButton class="goal-footer-btn start" data-bs-dismiss="modal" (click)="createIdeaGoal(true)" [label]="this.sumbitedStartDate ? ('my_plan_goal_modal_schedule_btn' | translate):('my_plan_goal_modal_start_now_btn' | translate)"></button>
          <button *ngIf="!createMode && previewMode && goalToPreview.status === 'to_do'" pButton class="goal-footer-btn start" (click)="startIdeaGoal()" data-bs-dismiss="modal" label="{{'my_plan_goal_modal_start_now_btn' | translate}}"></button>
          <button *ngIf="!createMode && previewMode && goalToPreview.status === 'in_progress'" pButton class="goal-footer-btn start" (click)="moveToDone()" data-bs-dismiss="modal" label="{{'my_plan_goal_modal_mark_done_btn' | translate}}"></button>
          <button *ngIf="editGoalMode" [disabled]="disableGoalCreationBtns" pButton class="goal-footer-btn start" (click)="saveGoalChanges()" label="{{'my_plan_goal_modal_save_changes_btn' | translate}}"></button>
          <button *ngIf="!createMode && !leaderPreviewMode && goalToPreview?.status === 'done'" pButton class="goal-footer-btn edit" (click)="moveToArchive()" data-bs-dismiss="modal" label="{{'my_plan_goal_modal_archive_btn' | translate}}"></button>
        </div>
      </div>
    </div>
</div>