import { Component, OnInit } from '@angular/core';
import { MyPlanServiceService } from '../services/my-plan-service.service';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-plan',
  templateUrl: './my-plan.component.html',
  styleUrls: ['./my-plan.component.scss']
})
export class MyPlanComponent implements OnInit {
  isOpenCreateGoalModal: boolean = false;
  gotMilestones: boolean = false;
  showOneOnOne: boolean = false;
  userHighPriority: boolean = false;
  isPreviewMemberGoals: boolean = false;
  showGoalsOverview: boolean = false;

  userGoals: any[] = [];
  unfilteredGoals: any[] = [];
  goalLabels: any[] = [];
  goalFrequencies: any[] = [];
  goalMilestones: any[] = [];
  oneOnOneMembers: any[] = [];

  openGoalModalDetails: any;
  goalToPriview: any;
  oneOnOneMember: any;

  constructor(private myPlanService: MyPlanServiceService, private data: DataManipulationService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.data.showLoading();
    this.setupCurrentUserMyPlan();
  }

  setupCurrentUserMyPlan() {
    this.getUserGoals();
    this.getAvailableGoalLabels();
    this.getAvailableGoalFrequencies();
    this.getUserPriorities();
  }

  getUserGoals() {
    this.myPlanService.getUserGoals().subscribe((res: any) => {
      this.userGoals = res._embedded.goals;
      this.unfilteredGoals = [...this.userGoals];
      if (this.userGoals.length === 0) {
        this.showGoalsOverview = true;
      } else {
        this.userGoals.forEach((goal:any) => {
          this.setGoalLabels(goal)
        });
      }
    });
  }

  setGoalLabels(goal: any) {
      this.myPlanService.getGoalLabels(goal.uuid).subscribe((res: any) => {
        goal.labels = res._embedded.labels;
        this.showGoalsOverview = true;
      });
  }

  getAvailableGoalLabels() {
    this.myPlanService.getAvailableLabels().subscribe((res: any) => {
      this.goalLabels = res._embedded.labels;
    });
  }

  getAvailableGoalFrequencies() {
    this.myPlanService.getGoalAvailableFrequencies().subscribe((res:any) => {
      this.goalFrequencies = res._embedded.frequencies;
      this.goalFrequencies.unshift({name: this.translate.instant('my_plan_goal_modal_not_repeat_freq'), slug: 'not_repeat'});
    });
  }

  getOneOnOneMembers(searchValue: any) {
    this.myPlanService.getOneOnOneMembers(searchValue).subscribe((res: any) => {
      this.oneOnOneMembers = [...res._embedded.members];
    });
  }

  getUserPriorities() {
    this.userHighPriority = this.data.userRole.teams.filter((team: any) => {
      if(team.role !== null) {
        return team.role.teamPriority > 10;
      }
      return false;
    }).length >= 1 ? true : false;
  }

  getGoalMilestones(goal: any) {
    this.myPlanService.getGoalMilestones(goal.uuid).subscribe((res:any) => {
      goal.milestones = res._embedded.milestones;
      this.goalToPriview = goal;
      this.openGoalModal('preview');
    });
  }

  addMilestone(milestoneToAdd: any) {
    this.myPlanService.createGoalMilestone({description: milestoneToAdd.milestone.description, isChecked: milestoneToAdd.milestone.isChecked, goalUuid: milestoneToAdd.goal.uuid}).subscribe((res: any) => {
      milestoneToAdd.goal.milestones.map((milestone: any) => {
        if (milestone.description === res.description) {
          milestone.uuid = res.uuid
        }
      });
    });
  }

  updateMilestone(milestone: any) {
    this.myPlanService.updateGoalMilestone(milestone.uuid, milestone.body).subscribe((res: any) => {
      milestone.isChecked = res.isChecked;
    });
  }

    openGoalModal(mode: any) {
    if (this.isPreviewMemberGoals) {
      this.openGoalModalDetails = {mode: 'leader-preview', goal: this.goalToPriview, open: true};
    } else {
      this.openGoalModalDetails = {mode: mode, goal: this.goalToPriview, open: mode === 'fromEdit' ? false : true};
    }
    this.isOpenCreateGoalModal = true;
  }

  openGoalPreview(goal: any) {
    this.getGoalMilestones(goal);
  }

  createGoal(event: any) {
    if (event) {
      this.myPlanService.createGoal(event.goalBody).subscribe((res: any) => {
        this.setGoalLabels(res);
        event.milestones.forEach((milestone: any) => {
          this.myPlanService.createGoalMilestone({description: milestone.description, goalUuid: res.uuid}).subscribe((res: any) => {
          });
        });
        if (event.start) {
          this.updateGoal(res.uuid, {status:'in_progress'}, false);
          res.status = 'in_progress';
        }
        if (!event.start) {
          this.userGoals = [... this.userGoals, res];
          this.unfilteredGoals = [... this.unfilteredGoals, res];
        }
      });
    }
    this.isOpenCreateGoalModal = false;
  }

  updateGoal(goalUuid: any, body: any, goalEdited: boolean) {
    this.myPlanService.updateGoal(goalUuid, body).subscribe((res:any) => {
      if (body.status !== 'archived') {
        this.setGoalLabels(res);
      }
      if (res.status === 'in_progress') {
        this.userGoals = [];
        this.getUserGoals();
        return;
      }
      if (goalEdited) {
        this.goalToPriview = res;
        this.openGoalModal('fromEdit');
      }
      this.userGoals = [... this.userGoals, res];
      this.unfilteredGoals = [... this.unfilteredGoals, res];
    });
  }

  startGoal(event: any) {
    let body = {
      status: 'in_progress',
      frequencyUuid: this.goalToPriview.frequency?.uuid !== event.goal.changedFrequency ? event.goal.changedFrequency:this.goalToPriview.frequency,
      dueDate: this.goalToPriview.dueDate?.date !== event.goal.changedDueDate ? event.goal.changedDueDate:this.goalToPriview.dueDate?.date,
      startDate: this.goalToPriview.startDate?.date !== event.goal.changedStartDate ? event.goal.changedStartDate:this.goalToPriview.startDate?.date
    }
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.goal.uuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.goal.uuid);
    this.updateGoal(event.goal.uuid, body, false);
  }

  completeGoal(event: any) {
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.goal.uuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.goal.uuid);
    this.updateGoal(event.goal.uuid, {status:'done'}, false);
  }

  editGoal(event: any) {
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.goalUuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.goalUuid);
    this.updateGoal(event.goalUuid, event.goalBody, event.goalEdited);
  }

  archiveGoal(event: any) {
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.updateGoal(event.uuid, {status:'archived'}, false);
  }

  moveGoalBackToIdea(event: any) {
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.updateGoal(event.uuid, {status:'to_do'}, false);
  }

  deleteGoal(event: any) {
    this.goalToPriview = null;
    this.userGoals = this.userGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.unfilteredGoals = this.unfilteredGoals.filter((goal: any) => goal.uuid !== event.uuid);
    this.myPlanService.deleteGoal(event.uuid).subscribe((res:any) => {});
  }

  goToOneOnOne() {    
    this.userGoals = [];
    this.oneOnOneMembers = [];
    this.getOneOnOneMembers('');
    this.isPreviewMemberGoals = false;
    this.showOneOnOne = true;
    this.showGoalsOverview = false;
  }

  previewMemberGoals(member: any) {
    this.showOneOnOne = false;
    this.showGoalsOverview = true;
    this.isPreviewMemberGoals = true;
    this.oneOnOneMember = member;
    this.myPlanService.getGoalsByUserUuid(member.uuid).subscribe((res:any) => {
      this.userGoals = [...res._embedded.goals];
      if (this.userGoals.length) {
        this.userGoals.forEach((goal:any) => {
          this.setGoalLabels(goal);
        });
      }
    });
  }

  goBackToOneOnOne() {
    this.userGoals = [];
    this.isPreviewMemberGoals = false;
    this.showOneOnOne = true;
    this.showGoalsOverview = false;
  }

  goBackToGoalsoverview() {
    this.data.showLoading();
    this.oneOnOneMember = null; 
    this.isPreviewMemberGoals = false;
    this.setupCurrentUserMyPlan();
    this.showOneOnOne = false;
  }

  setPrivateGoals(event: any) {
    if (!event.isPrivate && !event.isVisible) {
      this.userGoals = this.unfilteredGoals;
    } else {
      this.userGoals = this.unfilteredGoals.filter((goal: any) => goal.isVisible !== event.isPrivate);
    }
  }
}
