import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MyPlanServiceService } from '../../services/my-plan-service.service';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-goals-overview',
  templateUrl: './goals-overview.component.html',
  styleUrls: ['./goals-overview.component.scss']
})
export class GoalsOverviewComponent implements OnInit {

  @Input() set userGoals(value: any) {
    this.showLoading = true;
    this.emptyArrays();
    this.filterGoals(value);
  }

  @Input() set leaderPreviewMode(value: any) {
    this.isLeaderPreviewMode = value;
  }

  @Input() set memberPreviewed(value: any) {
    if (value) {
      this.member = value;
    }
  }

  @Output() openPreview = new EventEmitter<any>();
  @Output() deleteGoalEvent = new EventEmitter<any>();
  @Output() setPrivateGoals = new EventEmitter<any>();

  goalsToDo: any[] = [];
  goalsInProgress: any[] = [];
  goalsDone: any[] = [];
  goalsArchived: any[] = [];

  isPrivate: boolean = false;
  isVisible: boolean = false;
  showGoals: boolean = false;
  dragOverToDo: boolean = false;
  dragOverProgress: boolean = false;
  dragOverDone: boolean = false;
  showArchivedList: boolean = false;
  userHighPriority: boolean = false;
  isLeaderPreviewMode: boolean = false;
  showLoading: boolean = false;

  member: any;
  currentDate: Date = new Date();

  constructor( public data: DataManipulationService, private myPlanService: MyPlanServiceService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.getUserPriorities();
  }

  getUserPriorities() {
    this.userHighPriority = this.data.userRole.teams.filter((team: any) => {
      if(team.role !== null) {
        return team.role.teamPriority > 10;
      }
      return false;
    }).length >= 1 ? true : false;
  }

  filterGoals(goalsToFilter: any) {
    goalsToFilter.forEach((goal: any, index: number) => {
      if (goal.dueDate) {
        goal.dueDateToCompare = new Date(goal.dueDate.date);
      }
      if (goal.startDate) {
        goal.startDateToCompare = new Date(goal.startDate.date);
      }
      if (goal.frequency) {
        if (goal.frequency.slug !== 'not_repeat') {
          if (goal.frequency.slug === 'weekly' && goal.frequency.name === this.translate.instant('my_plan_goals_overview_frequency_1')) {
            goal.frequency.name = goal.frequency.name +' '+ (goal.startDate !== null ? new Date(goal.startDate.date).toLocaleDateString('en-US', { weekday: 'long' }) : '');
          } else if (goal.frequency.slug === 'montly'&& goal.frequency.name === this.translate.instant('my_plan_goals_overview_frequency_2')) {
            goal.frequency.name = goal.frequency.name +' '+ (goal.startDate !== null ? new Date(goal.startDate.date).getDate()+'th' : '');
          } else if (goal.frequency.slug === 'annually' && goal.frequency.name === this.translate.instant('my_plan_goals_overview_frequency_3')) {
            goal.frequency.name = goal.frequency.name+' ' + (goal.startDate !== null ? formatDate(goal.startDate.date,'dd MMM', 'en-US') : '');
          }
        }
      }
      switch(goal.status) {
        case 'to_do':
          this.goalsToDo.push(goal);
          break;
        case 'in_progress':
          this.goalsInProgress.push(goal);
          break;
        case 'done':
          this.goalsDone.push(goal);
          break;
        case 'archived':
          this.goalsArchived.push(goal);
          break;
      }
      if ((goalsToFilter.length - 1) === index) {
        this.showGoals = true;
        this.data.hideLoading();
      }
    });
    if (goalsToFilter.length === 0) {
      this.showGoals = true;
      this.data.hideLoading();
    }
    setTimeout(() => {
      this.showLoading = false;
    }, 700);
  }

  

  drop(event: any) {
    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      // post API whit new status of action
      // to_do / in_progress /  done / archived
      if (event.container.id === 'todoList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'to_do');
      }  if (event.container.id === 'progressList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'in_progress');
      } else if (event.container.id === 'doneList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'done');
      } else if (event.container.id === 'archiveList') {
        // move form ToDoList to InProgressList
        this.moveAction(event, 'archived');
      }
    }
  }

  moveAction(event: any, status: string) {    
    const task = event.previousContainer.data[event.previousIndex];
    this.data.showLoading();
    let goalBody = {
      status: status,
    };
    this.myPlanService.updateGoal(task.uuid, goalBody).subscribe((res: any) => {
      res.labels = task.labels;
      switch (event.previousContainer.id) {
        case 'todoList': 
          if(this.goalsToDo[event.previousIndex]['uuid'] === task.uuid) {
            this.goalsToDo[event.previousIndex]['status'] = res.status;
          }
          break;
        case 'progressList':
          if(this.goalsInProgress[event.previousIndex]['uuid'] === task.uuid) {
            this.goalsInProgress[event.previousIndex]['status'] = res.status;
          }
          break;
        case 'doneList':
          if(this.goalsDone[event.previousIndex]['uuid'] === task.uuid) {
            this.goalsDone[event.previousIndex]['status'] = res.status;
          }
          break;
        case 'archiveList':
          if(this.goalsArchived[event.previousIndex]['uuid'] === task.uuid) {
            this.goalsArchived[event.previousIndex]['status'] = res.status;
          }
          break;
      }
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      event.container.data[event.currentIndex] = res;
      this.data.hideLoading();
    }, err => {
      this.data.showAlertError(err.error.error.messages[0]);
      this.data.hideLoading();
    });
  }

  dragOverSetter(event: any) {
    switch(event.container.id) {
      case 'todoList':
        this.dragOverToDo = true;
        this.dragOverProgress = false;
        this.dragOverDone = false;
        break;
      case 'progressList':
        this.dragOverToDo = false;
        this.dragOverProgress = true;
        this.dragOverDone = false;
        break;
      case 'doneList':
        this.dragOverToDo = false;
        this.dragOverProgress = false;
        this.dragOverDone = true;
        break;
    }
  }

  deleteGoal(goalToDelete: any) {
    this.deleteGoalEvent.emit(goalToDelete.uuid);
  }

  setPrivateGoalsOn() {
    this.isPrivate = !this.isPrivate;
    this.isVisible = false;
    this.setPrivateGoals.emit({isPrivate: this.isPrivate, isVisible: this.isVisible});
  }

  setPrivateGoalsOff() {
    this.isVisible = !this.isVisible;
    this.isPrivate = false;
    this.setPrivateGoals.emit({isPrivate: this.isPrivate, isVisible: this.isVisible});
  }

  openModalInPreview(goal: any) {
    this.openPreview.emit(goal);
  }

  emptyArrays() {
    this.goalsToDo = [];
    this.goalsInProgress = [];
    this.goalsDone = [];
  }

  seeArchived() {
    this.showArchivedList = !this.showArchivedList;
  }

}
