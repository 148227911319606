import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective {
  @Input('appAutofocus') shouldFocus: boolean = true;

  constructor(private element: ElementRef<HTMLInputElement>) { }

  ngOnInit(): void {
    if (this.shouldFocus) {
      setTimeout(() => {
        this.element.nativeElement.focus();
      }, 0);
    }
  }
}
