import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { DataManipulationService } from '../services/data-manipulation.service';
import { ApiwrapperService } from '../services/apiwrapper.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddonGuard implements CanActivate {
  constructor(private data: DataManipulationService, private api: ApiwrapperService, private router: Router) {}
  canActivate(): Observable<boolean | UrlTree> {
    return new Observable((subscriber) => {
      this.api.getWorkspaceAddons().subscribe((res) => {
        if (res._embedded.addons.filter((addon: any) => addon.addOn.slug === 'myplan').length === 1) {
          subscriber.next(true);
        } else {
          subscriber.next(this.router.parseUrl('/'));
        }
        subscriber.complete();
      });
    });
  }
}
