import {Injectable} from '@angular/core';
// NOTIFICATIONS
import {Notify} from 'notiflix/build/notiflix-notify-aio';
import {Report} from 'notiflix/build/notiflix-report-aio';
import {Confirm} from 'notiflix/build/notiflix-confirm-aio';
import {Loading} from 'notiflix/build/notiflix-loading-aio';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {ApiwrapperService} from "./apiwrapper.service";
import {environment} from "../../environments/environment";
import moment from 'moment';
import {formatDate} from "@angular/common";
import {Router} from "@angular/router";
import {Block} from "notiflix/build/notiflix-block-aio";
import {AuthenticationService} from "./authentication.service";


@Injectable({
  providedIn: 'root'
})
export class DataManipulationService {
  userRole: any;
  colors = {
    red: '#E83032',
    redGradient: 'linear-gradient(#E83032, #BE1A1C)',
    orange: '#FE9001',
    orangeGradient: 'linear-gradient(#FE9001, #DC7E04)',
    yellow: '#FFC828',
    yellowGradient: 'linear-gradient(#FFC828, #E6AB00)',
    green: '#00BD82',
    greenGradient: 'linear-gradient(#00BD82, #029B6B)',
    grey: '#dcdde1',
    greyGradient: 'linear-gradient(#ECEFF8, rgb(227 233 250))',
    white: '#ffffff'
  };
  notificationsBadge = 0;
  isMobile = false;
  onboardingStep = 0;
  startGuideStep = 0;
  mailSent = false;
  forgetEmail = '';
  private workspaceAddedSource = new BehaviorSubject(false);
  workspaceAdded = this.workspaceAddedSource.asObservable();
  private userPrioritiesSource = new BehaviorSubject([]);
  userPriorities = this.userPrioritiesSource.asObservable();
  private changedUserDataSource = new BehaviorSubject(false);
  changedUserData = this.changedUserDataSource.asObservable();
  public changeName: Subject<boolean> = new Subject<boolean>();
  private menuToggleSubscription = new BehaviorSubject(false);
  menuButtonSubs = this.menuToggleSubscription.asObservable();
  private refreshHeaderNotificationsSource = new BehaviorSubject(false);
  refreshHeaderNotifications = this.refreshHeaderNotificationsSource.asObservable();
  private menuShowPollSource = new BehaviorSubject(false);
  menuShowPoll = this.menuShowPollSource.asObservable();
  private workspaceChangedSource = new BehaviorSubject(false);
  workspaceChanged = this.workspaceChangedSource.asObservable();
  private refreshMenuTeamsSource = new BehaviorSubject(false);
  refreshMenuTeams = this.refreshMenuTeamsSource.asObservable();
  private findSolution = new BehaviorSubject(false);
  findSolutionsChanged = this.findSolution.asObservable();
  private addedMember = new BehaviorSubject(false);
  addedMemberNowAddToTeam = this.addedMember.asObservable();
  private fromAnalyzeToAction = new BehaviorSubject(false);
  fromAnalyzeToActionObs = this.fromAnalyzeToAction.asObservable();
  private collectorModifiedSubscription = new BehaviorSubject(false);
  collectorModSubs = this.collectorModifiedSubscription.asObservable();
  private refreshPollsSource = new BehaviorSubject(false);
  refreshPolls = this.refreshPollsSource.asObservable();
  private refreshTeamPollsSource = new BehaviorSubject(false);
  refreshTeamPolls = this.refreshTeamPollsSource.asObservable();
  private refreshTeamMembersSource = new BehaviorSubject(false);
  refreshTeamMembers = this.refreshTeamMembersSource.asObservable();
  private collectorDashboardSource = new BehaviorSubject(false);
  collectorDashboard = this.collectorDashboardSource.asObservable();
  private chartOfImprovementsSubscription = new BehaviorSubject(false);
  chartSubs = this.chartOfImprovementsSubscription.asObservable();
  private refreshMembers = new BehaviorSubject(false);
  refreshMembersBox = this.refreshMembers.asObservable();
  private collectorShow = new BehaviorSubject(false);
  collectorShowSub = this.collectorShow.asObservable();

  private teamPoll = new BehaviorSubject(false);
  teamPollObs = this.teamPoll.asObservable();
  private teamPollDone = new BehaviorSubject(false);
  teamPollDoneObs = this.teamPollDone.asObservable();
  private leaderCollector = new BehaviorSubject(false);
  leaderCollectorObs = this.leaderCollector.asObservable();
  private startGuideSub = new BehaviorSubject(false);
  startGuideSubObs = this.startGuideSub.asObservable();
  public showHomeMenu: string = '';

  private gotMembers = new BehaviorSubject(false);
  gotMembersSubs = this.gotMembers.asObservable();
  allMembers: any = [];
  membersInvited: any = [];
  getAllMembers: boolean = false;

  private gotTeams = new BehaviorSubject(false);
  gotTeamsSubs = this.gotTeams.asObservable();
  private gotOrganizeTeams = new BehaviorSubject(false);
  gotOrganizeTeamsSubs = this.gotOrganizeTeams.asObservable();
  // all workspace teams
  allTeams: any = [];
  getAllTeams: boolean = false;
  // teams set for organize
  organizeTeams = [];
  openFromTeam = false;
  openFromDashboard = false;
  // teams set for collector
  collectorTeams: any = [];
  editCollector = false;
  nextCollector = '';
  pauseCollector = false;

  private gotTeamRoles = new BehaviorSubject(false);
  gotTeamRolesSubs = this.gotTeamRoles.asObservable();
  roleWorkspaceAdmin: string = '';
  roleWorkspaceMember: string = '';

  public changeImage: Subject<boolean> = new Subject<boolean>();

  public currentTeam: any;
  public workspaceMembers = [];
  public editCurrentMembers = [];

  public allCurrentTeamMembers = [];
  public editCurrentTeam: any;
  public editTeamCurrentMembers = [];
  workspaceMembersForTeam = [];
  public workspaceInvitation: string = environment.frontendUrl + '/add-to-workspace/';
  public showInvitation: boolean = false;
  paymentFail: boolean = false;
  private teamRoles = [];
  private gettingTeamRoles = false;
  teamRoleMember = {};
  teamRoleAdmin = {};
  private indicatorTeam: any;
  private teamsIndicatorMembers = [];
  private teamsIndicatorAdmins = [];
  private teamsMembers = [];
  addMemberTeam: any;
  showMyActionsClicked = '';
  private currentTask = {};
  private getApiMembers = false;
  showTeamActions = false;
  teamAnalyseReport = [];
  teamProblemsNumber = {
    performance: 0,
    motivation: 0,
    satisfaction: 0,
    stress: 0
  };
  private teamProblemsTasks: any;
  teamProblems: any;
  teamIndicators: any;
  teamIndicatorTasks = {
    individual: {
      performance: [],
      motivation: [],
      satisfaction: [],
      stress: []
    },
    team: {
      performance: [],
      motivation: [],
      satisfaction: [],
      stress: []
    },
    leadership: {
      performance: [],
      motivation: [],
      satisfaction: [],
      stress: []
    },
    totalProblems: 0,
    performance: 0,
    motivation: 0,
    satisfaction: 0,
    stress: 0,
  };
  showMyActions = false;
  showTeamAnalyse = false;
  activPoll = {};
  activPollExists = false;
  teamNewProblems = [];
  showNewProblems = false;
  myActions = false;
  indicatorActionTask = [];
  private teams = [];
  private libraryTeam: any;
  couponData = {};
  private user: any;
  baseUrl: string | undefined;
  isInvited: boolean = false;
  editTeamCollector: any;
  teamCollector = {
    teamUuid: '', teamCollector: {
      uuid: ""
    }
  }
  registeredMember: any;
  teamLeaders: any[] = [];
  inviteMessage: string = "Hi Team\uD83D\uDC4B\uD83C\uDFFC,\n\nWe have made the decision to implement Successteam as a tool to increase wellbeing. Successteam is a digital platform specifically designed to strengthen collaboration, culture and leadership by collecting feedback from each employee about the team you are part of. Our goal is to ensure that management has full insight into key motivators so we can continuously improve satisfaction and ensure everyone's well-being.\n\nThrough Successteam, you are encouraged to regularly express your level of agreement with various statements. Your individual responses remain 100% anonymous - always!\n\nYou will receive an email when it's time to share your feedback. We kindly ask you to take a few minutes to answer the questions so that we can create a good working environment where everyone thrives together.\n\nWe can't wait to hear your feedback! \uD83D\uDE4C\uD83C\uDFFB";
  showDemo: boolean = false;
  showMobileWarning = false;
  newTeamCreated: string = '';
  showUpgradePlan: Boolean = true;
  autoOpenMemberFeedback: boolean = false;
  wizardOn: boolean = false;
  lastRouteBeforeBilling: string = '';
  notApplicable: any = [];
  apiLanguages: any = [];
  language: any = {};
  userLink: any = {};
  workspaceOwnerName: string = '';
  workspaceCreatorImage: string = '';
  settingsWorkspaceMembers: boolean = false;
  currentUserImmediateLeader: any | null;
  loadMembers: boolean = false;
  workspaceMembersPage = 1;
  workspaceMaxMembersPage = 0;
  currentUserTeamMemberUuid: any
  immediateLeadersFromTeam: any;
  immediateLeadersOutsideTeam: any;
  otherImmediateLeaders: any;
  private workspaceAddons = new BehaviorSubject([]);
  workspaceAddonsSub = this.workspaceAddons.asObservable();
  hasMyPlanAddon: boolean = false;
  myPlanAddon: any;

  selectedCustomPoll: any;

  constructor(private api: ApiwrapperService, private router: Router) {
    this.isMobile = navigator.userAgent.toLowerCase().includes('mobile');
    // @ts-ignore
    this.user = JSON.parse(localStorage.getItem('userDetails'));
    this.baseUrl = environment.baseUrl;
  }

  getWorkspaceInv() {
    this.api.getWorkspaceInvitation().subscribe(
      res => {
        this.workspaceInvitation = this.workspaceInvitation === environment.frontendUrl + '/add-to-workspace/' ?
          this.workspaceInvitation += res.hash : environment.frontendUrl + '/add-to-workspace/' + res.hash;
        this.showInvitation = true;
      }
    );
  }

  getTeamInv(teamUuid: string | undefined) {
    this.api.getTeamInvitation(teamUuid).subscribe(
      res => {
        this.workspaceInvitation = this.workspaceInvitation === environment.frontendUrl + '/add-to-workspace/' ?
          this.workspaceInvitation += res.hash : environment.frontendUrl + '/add-to-workspace/' + res.hash;
        this.showInvitation = true;
      }
    );
  }

  setWorkspaceBillingInfo(workspaceBillingInfo: any) {
    localStorage.setItem('workspaceBillingInfo', JSON.stringify(workspaceBillingInfo));
  }

  getWorkspaceBillingInfo() {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('workspaceBillingInfo'));
  }

  setWorkspaceBillingContacts(workspaceBillingContacts: any) {
    localStorage.setItem('workspaceBillingContacts', JSON.stringify(workspaceBillingContacts));
  }

  setChangeImage(changed: boolean) {
    this.changeImage.next(changed);
  }

  getWorkspaceBillingContacts() {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('workspaceBillingContacts'));
  }

  getWorkspaceAddons() {
    this.api.getWorkspaceAddons().subscribe((res:any) => {
      if (res._embedded.addons.length) {
        this.workspaceAddons.next(res._embedded.addons);
      }
    });
  }

  getMyPlanAddon() {
    this.workspaceAddonsSub.subscribe((res:any) => {
      res.forEach((addOn: any) => {
        if (addOn.addOn.slug === 'myplan') {
          this.myPlanAddon = addOn.addOn;
          this.hasMyPlanAddon = true;
        }
      });
    });
  }

  setWorkspacePlan(workspacePlan: any) {
    localStorage.setItem('workspacePlan', JSON.stringify(workspacePlan));
  }

  getWorkspacePlan() {
    // @ts-ignore
    return JSON.parse(localStorage.getItem('workspacePlan'));
  }

  setWorkspaceChanged(value: boolean) {
    this.workspaceChangedSource.next(value);
  }

  setChangedUserData(changed: boolean) {
    this.changedUserDataSource.next(changed);
  }

  setChangeName(changed: boolean) {
    this.changeName.next(changed);
  }

  setRefreshHeaderNotifications(value: boolean) {
    this.refreshHeaderNotificationsSource.next(value);
  }

  setMenuPoll(value: boolean) {
    this.menuShowPollSource.next(value);
  }

  setRefreshMenuTeams(value: boolean) {
    this.refreshMenuTeamsSource.next(value);
  }

  addedMembersToTeam(value: boolean) {
    this.refreshTeamMembersSource.next(value);
  }

  dashboardCollector(value: boolean) {
    this.refreshTeamMembersSource.next(value);
  }

  setUserRoles(roles: any) {
    this.userRole = roles;
    this.userPrioritiesSource.next(roles);
  }

  setWorkspaceAdded(value: boolean) {
    this.workspaceAddedSource.next(value);
  }


  setEditTeam(team: any) {
    this.editCurrentTeam = team;
  }

  setUserTeams(res: any) {
    this.teams = res;
  }

  setRefreshMembers(changed: boolean) {
    this.refreshMembers.next(changed);
  }

  setShowCollector(changed: boolean) {
    this.collectorShow.next(changed);
  }

  setLeaderCollector(changed: boolean) {
    this.leaderCollector.next(changed);
  }

  getWorkspaceMembers(page: number) {
    // @ts-ignore
    let me = JSON.parse(localStorage.getItem('WORKSPACE')).creator;
    this.api.getWorkspacesUserWithPagination(page).subscribe(
      res => {
        if (page === 1){
          this.allMembers = [];
        }
        this.workspaceMembersPage = page;
        this.workspaceMaxMembersPage = res._page_count;
        this.membersInvited = [];
        res._embedded.users.forEach((el: {
          checked: boolean;
          teams: never[];
        }) => {
          el.teams = [];
          el.checked = false;
          // if single team populate team list
          // if (this.onboardingStep === 7) {
          //   // @ts-ignore
          //   if (me.email === el.user.email) {
          //     this.teamLeaders.push(el);
          //   } else {
          //     this.teamMembers.push(el);
          //   }
          // }
          // @ts-ignore
          if (el.user.email !== this.user.email) {
            this.membersInvited.push(el);
          }
        });
        this.allMembers = this.allMembers.concat(res._embedded.users);
        // if (res._page_count > 1 && this.workspaceMembersPage <= res._page_count) {
        //   this.workspaceMembersPage++;
        //   this.getWorkspaceMembers(page);
        // }
        this.setGotMembers(true);
        this.getAllMembers = true;
        this.loadMembers = false
      }
    );
  }

  setGotMembers(changed: boolean) {
    this.gotMembers.next(changed);
  }

  // !!! call to get all teams
  getWorkspaceTeams() {
    if (!this.getAllTeams) {
      this.api.getTeamsFromWorkspace().subscribe(
        res => {
          this.getAllTeams = true;
          res.forEach((el: any) => {
            el.leaders = 0;
            el.members = 0;
          })
          this.allTeams = res;
          this.setGotTeams(true);
        }, err => {
          console.log(err);
        }
      )
    }
  }

  setGotTeams(changed: boolean) {
    this.gotTeams.next(changed);
  }

  setOrganizeTeams(changed: boolean) {
    this.gotOrganizeTeams.next(changed);
  }

  updateTeamMembers(team: any) {
    this.editCurrentMembers = [];
    this.editTeamCurrentMembers = [];
    this.editCurrentTeam = team;
    // @ts-ignore
    this.editTeamCurrentMembers.push({email: '', label: ''});
  }

  getTeamRoles() {
    if (this.teamRoles.length > 0 || this.gettingTeamRoles) {
      return this.teamRoles;
    } else {
      this.gettingTeamRoles = true;
      this.api.getAllRoles('team').subscribe(
        res => {
          res.splice(0, 1);
          res.forEach((el: { name: string; }) => {
            if (el.name === 'team-member') {
              this.teamRoleMember = el;
            } else if (el.name === 'team-admin') {
              this.teamRoleAdmin = el;
            }
          });
          this.teamRoles = res;
          return this.teamRoles;
        }
      );
      return this.teamRoles;
    }
  }

  getAllTeamMembers() {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      // @ts-ignore
      // this.api.getTeamMembers(this.currentTeam.uuid).subscribe(res => {
      //     this.allCurrentTeamMembers = res;
      //     resolve(this.allCurrentTeamMembers);
      //   }
      // );
    });
  }

  getTeamMembers() {
    this.teamsIndicatorMembers = [];
    this.teamsIndicatorAdmins = [];
    this.api.getTeamMembers(this.indicatorTeam).subscribe(res => {
      //@ts-ignore
      res.forEach((el) => {

        el.user.memberUuid = el.uuid;
        el.user.displayName = this.getUserDisplayName(el.user);
        // @ts-ignore
        this.teamsIndicatorMembers.push(el.user);
        // @ts-ignore
        if (el.user.teamRole.uuid === this.teamRoleAdmin.uuid) {
          // @ts-ignore
          this.teamsIndicatorAdmins.push(el.user);
        }
      });
      this.teamsMembers = res;
      return this.teamsMembers;
    }, err => {
      console.log(err);
    });
  }

  getTeamMembersToAdd(teamMembers: any[]) {
    const workspaceMembers = [...this.workspaceMembers];
    teamMembers.forEach((teamMember) => {
      if (teamMember.teamMember) {
        // @ts-ignore
        if (workspaceMembers.indexOf(teamMember.teamMember.user)) {
          workspaceMembers.forEach((el, index) => {
            // @ts-ignore
            if (el.user.uuid === teamMember.teamMember.user.uuid) {
              workspaceMembers.splice(index, 1);
            }
          });
        }
      } else {
        // @ts-ignore
        if (workspaceMembers.indexOf(teamMember.user)) {
          workspaceMembers.forEach((el, index) => {
            // @ts-ignore
            if (el.user.uuid === teamMember.user.uuid) {
              workspaceMembers.splice(index, 1);
            }
          });
        }
      }
    });
    this.setTeamToAdd(workspaceMembers);
  }

  getEditTeamCurrentMembers() {
    this.getAllTeamMembers();
    return new Promise((resolve, reject) => {
      this.api.getWorkspacesUser().subscribe(
        res => {
          this.editTeamCurrentMembers = res;
          this.workspaceMembers = res;
          this.setWorkspaceMembers(res);
          // return this.workspaceMembers;
          resolve(this.editTeamCurrentMembers);
        }
      );
    });
  }

  setWorkspaceMembers(data: any) {
    this.workspaceMembersForTeam = [];
    // remove my account from members
    this.workspaceMembers = data;
    data.forEach((el: {
      user: { uuid: any; email: any; detail: { firstname: any; lastname: any; }; };
      email: any;
      displayName: any;
      firstname: any;
      lastname: any;
    }) => {
      // @ts-ignore
      if (JSON.parse(localStorage.getItem('userDetails')).uuid === undefined) {
        this.api.cashRemoval();
      }
      // @ts-ignore
      if (el.user.uuid !== JSON.parse(localStorage.getItem('userDetails')).uuid) {
        el.email = el.user.email;
        el.displayName = this.getUserDisplayName(el.user);
        el.firstname = el.user.detail.firstname;
        el.lastname = el.user.detail.lastname;
        // @ts-ignore
        this.workspaceMembersForTeam.push(el);
      }
    });
  }

  setTeamToAdd(workspaceMembers: any[]) {
    // @ts-ignore
    this.workspaceMembersForTeam = [...workspaceMembers];
    this.workspaceMembersForTeam.forEach(item => {
      // @ts-ignore
      item.email = item.user.email;
      // @ts-ignore
      item.firstname = item.user.detail.firstname;
      // @ts-ignore
      item.lastname = item.user.detail.lastname;
      // @ts-ignore
      item.displayName = this.getUserDisplayName(item.user);
    });
  }

  getUserDisplayName(user: { uuid?: any; email: any; detail: any; }) {
    if (user.detail.displayName === null || user.detail.displayName === '' || user.detail.displayName === undefined) {
      if (user.detail.firstname === null || user.detail.lastname === null || user.detail.firstname === undefined ||
        user.detail.lastname === undefined || user.detail.firstname === '' || user.detail.lastname === '') {
        return user.email;
      } else {
        return user.detail.firstname + ' ' + user.detail.lastname;
      }
    } else {
      return user.detail.displayName;
    }
  }

  showLoading() {
    Loading.pulse('Loading...');
  }

  hideLoading() {
    Loading.remove();
  }

  public showAlert(text: string) {
    Notify.success(text);
  }

  public showAlertWarning(text: string,) {
    Notify.warning(text);
  }

  public showAlertError(text: string,) {
    Notify.failure(text);
  }

  public showAlertInfo(text: string,) {
    Notify.info(text);
  }

  showAlertReport(title: string, text: string) {
    Report.success(title, text, 'Ok 😅');
  }

  setIndicatorTeam(uuid: any) {
    this.indicatorTeam = uuid;
  }

  rateAction(task: { title?: any; }) {
    // this.spinner.show();
    this.currentTask = task;
    // document.getElementById('rateModal').click();
    this.api.getTaskReview().subscribe(
      res => {
        // this.spinner.hide();
        // @ts-ignore
        this.currentTask.answers = res;
        // @ts-ignore
        this.currentTask.title = task.title;
        // @ts-ignore
        // this.currentTask.indicator = task.indicator.title;
        document.getElementById('rateModal').click();
      }
    );
  }

  // @ts-ignore
  getTeamsUsers(getUsers) {
    if (this.teamsMembers.length === 0 && getUsers) {
      if (!this.getApiMembers && this.indicatorTeam) {
        this.getApiMembers = true;
        this.getTeamMembers();
      }
    } else if (this.teamsMembers.length !== 0 && getUsers) {
      return this.teamsMembers;
    } else if (!getUsers) {
      this.getTeamMembers();
    }
  }

  getLastDays(days: number) {
    // endDate - today with hour:min
    return {today: moment().format().substring(0, 16), next: moment().subtract(days, 'days').format().substring(0, 10)};
  }

  resetTeamProblemNumbers() {
    this.teamProblemsNumber = {
      performance: 0,
      motivation: 0,
      satisfaction: 0,
      stress: 0
    };
  }

  processTeamProblem(categ: any) {
    this.teamProblemsTasks = Object.assign({}, this.teamIndicatorTasks);
    this.teamProblems = [];
    this.teamIndicators = categ.categories;
    categ.categories.forEach((el: { indicatorsReport: any[]; category: { title: any; }; }) => {
      el.indicatorsReport.forEach((ind) => {
        // if contains red or orange store problem ( indicator uuid / color / category name / indicator name)
        this.teamProblemsTasks.totalProblems = this.teamProblemsTasks.totalProblems + ind.tasks.length;
        this.teamProblemsTasks[(ind.indicator.title).toLowerCase()] = this.teamProblemsTasks[(ind.indicator.title).toLowerCase()]
          + ind.tasks.length;
        this.teamProblemsTasks[(el.category.title).toLowerCase()][(ind.indicator.title).toLowerCase()] = ind.tasks;
        if (ind.image.includes('yellow')) {
          this.teamProblems.push({
            // @ts-ignore
            color: 'yellow',
            // @ts-ignore
            hexColor: this.colors.yellow,
            // @ts-ignore
            challengeUuid: '',
            // @ts-ignore
            uuid: ind.indicator.uuid,
            // @ts-ignore
            category: el.category.title,
            // @ts-ignore
            indicator: ind.indicator.title,
            // @ts-ignore
            status: 'seen'
          });
        } else if (ind.image.includes('orange')) {
          this.teamProblems.push({
            // @ts-ignore
            color: 'orange',
            // @ts-ignore
            hexColor: this.colors.orange,
            // @ts-ignore
            challengeUuid: '',
            // @ts-ignore
            uuid: ind.indicator.uuid,
            // @ts-ignore
            category: el.category.title,
            // @ts-ignore
            indicator: ind.indicator.title,
            // @ts-ignore
            status: 'seen'
          });
        } else if (ind.image.includes('red')) {
          this.teamProblems.push({
            // @ts-ignore
            color: 'red',
            // @ts-ignore
            hexColor: this.colors.red,
            // @ts-ignore
            challengeUuid: '',
            // @ts-ignore
            uuid: ind.indicator.uuid,
            // @ts-ignore
            category: el.category.title,
            // @ts-ignore
            indicator: ind.indicator.title,
            // @ts-ignore
            status: 'seen'
          });
        }
      });
    });
  }

  setTeamProblemNumbers(problem: string, problemNumber: any) {
    // @ts-ignore
    this.teamProblemsNumber['' + problem] = problemNumber;
  }

  // TODO CHECK THE PRIORITY  - if the user is team member and workspace leader return team member
  getMyTeamRole(teamUuid: string | null) {
    // if user has creator give user role 20 else role 10
    return new Promise((resolve, reject) => {
      this.userPriorities.subscribe((value: any) => {
        if (value.teams) {
          //@ts-ignore
          value.teams.forEach((el) => {
            if (el.team.uuid === teamUuid) {
              if (el.role !== null) {
                resolve(el.role.teamPriority)
              } else {
                resolve(value.role.workspacePriority)
              }
              // if (value.role.workspacePriority > 20) {
              //   resolve(20);
              // } else if (el.role !== null) {
              //   resolve(el.role.teamPriority);
              // } else {
              //   resolve(10);
              // }
            }
          });
        }
      });
    });
  }

  //@ts-ignore
  getDate(date) {
    const newDate = new Date();
    if (date === null) {
      return formatDate(newDate, 'dd MMM yyyy', 'en-US') + '';
    } else {
      return formatDate(date, 'dd MMM yyyy', 'en-US') + '';
    }
  }

  //@ts-ignore
  getDateNoYear(date) {
    const newDate = new Date();
    if (date === null) {
      return formatDate(newDate, 'dd MMM', 'en-US') + '';
    } else {
      return formatDate(date.date, 'dd MMM', 'en-US') + '';
    }
  }

  setRefreshTeamPolls(value: boolean) {
    this.refreshTeamPollsSource.next(value);
  }

  setRefreshPolls(value: boolean) {
    this.refreshPollsSource.next(value);
  }

  setTeamCollectorSubs(value: boolean) {
    this.teamPoll.next(value);
  }


  setMenuStartGuide(value: boolean) {
    this.startGuideSub.next(value);
  }

  setChartSubscription(changed: boolean) {
    this.chartOfImprovementsSubscription.next(changed);
  }

  getWorkspaceDetails() {
    const currentWorkspace = localStorage.getItem('WORKSPACE_UUID');
    // @ts-ignore
    this.api.getWorkspaces(currentWorkspace).subscribe(
      res => {
        this.setWorkspaceBillingInfo(res.workspaceBillingInfo);
        res.workspaceSubscription.workspacePlan.billDate = res.billDate.date;
        this.setWorkspacePlan(res.workspaceSubscription.workspacePlan);
        this.setWorkspaceBillingContacts(res.workspaceBillingContacts);
      }, err => {
        console.log(err);
      }
    );
  }

  parseError(error: { [x: string]: string; }) {
    let string1 = '';
    for (const key of Object.keys(error)) {
      let str = key + ': ';
      if (typeof error[key] === 'object') {
        for (const k of Object.keys(error[key])) {
          // @ts-ignore
          str += error[key][k] + '. ';
        }
      } else {
        str += error[key];
      }
      string1 += str;
    }
    return string1;
  }

  getLibraryTeam() {
    if (this.libraryTeam) {
      return this.libraryTeam;
    } else {
      return false;
    }
  }

  patchWorkspaceInvitation(data: any, invitationUuid: any) {
    return new Promise((resolve, reject) => {
      this.api.patchWorkspaceInvitation(data, invitationUuid).subscribe(
        res => {
          this.showAlert('Your invitation was successfully updated!');
          resolve(true);
          // this.showAlertSuccess('Invitation updated successfully!', '');
        }, err => {
          reject();
          this.showAlertError('Unable to update invitation!');
        }
      );
    });
  }

  calculateDays(dateSent: string) {
    const currentDate = new Date();
    const date = new Date(dateSent.slice(0, 10));
    return Math.floor((Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
      Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  userTeams() {
    let teams: any[] = []
    this.userRole.teams.forEach((el: any) => {
      el.team.myRole = el.role;
      if (this.userRole.role.workspacePriority <= 20 && el.role !== null) {
        teams.push(el.team);
      } else if (this.userRole.role.workspacePriority > 20) {
        // @ts-ignore
        teams.push(el.team);
      }
    })
    return teams;
  }

  setTeamLeaders(teamLeadersReport: any) {
    this.teamLeaders = [];
    teamLeadersReport.forEach((el: any) => {
      this.teamLeaders.push(el.teamMember);
    })
  }

  copyInvitationLink(onlyLink: boolean) {
    if(this.language.language === 'en') {
      this.showAlert(onlyLink ? 'Invitation link copied' : 'Invitation message copied\n');
    } else {
      this.showAlert(onlyLink ? 'Link til invitation kopieret' : 'Invitationsmeddelelse kopieret\n');
    }

    navigator.clipboard.writeText(onlyLink ? this.workspaceInvitation : this.inviteMessage + '\n' + this.workspaceInvitation);
  }

  getWorkspaces() {
    this.showLoading()
    // @ts-ignore
    const workspace = JSON.parse(localStorage.getItem('WORKSPACE'));
    this.api.getWorkspaces(workspace.uuid).subscribe(
      resolve => {
        location.reload();
      }
    );
  }

  goToTeamCreated() {
    if (this.newTeamCreated !== '') {
      const team = this.newTeamCreated;
      this.newTeamCreated = '';
      if (this.startGuideStep === 0) {
        this.router.navigate(['/team/' + team]);
      } else {
        this.addedMembersToTeam(true);
      }
    }
  }

  getUserLink() {
    this.api.getWorkspaceUser().subscribe(
      res => {
        this.userLink = res;
      }
    )
  }

  setUserLink(uuid: string, body: object) {
    this.api.updateWorkspaceUser(uuid, body).subscribe(
      res => {
        this.userLink = res;
      }
    )
  }

  setCustomPoll(poll: any) {
    this.selectedCustomPoll = poll;
  }
}
