import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { DataManipulationService } from 'src/app/services/data-manipulation.service';

@Component({
  selector: 'app-one-on-one',
  templateUrl: './one-on-one.component.html',
  styleUrls: ['./one-on-one.component.scss']
})
export class OneOnOneComponent implements OnInit {
  @Input() set oneOnOneMembers(value: any) {
    this.membersList= value;
    this.showMembersLoading = value.length ? false:true;
  }
  @Output() searchMember = new EventEmitter<any>();
  @Output() memberChosedEvent = new EventEmitter<any>();

  searching = new Subject<string>();
  showMembersLoading: boolean = false;
  
  membersList: any[] = [];

  searchValue: any;
  
  constructor(public data: DataManipulationService) {
    this.searching.pipe(
      debounceTime(800),
      distinctUntilChanged()).subscribe((value: any) => {
        this.membersList = [];
        this.showMembersLoading = true;
        this.searchMember.emit(value);
      });
   }

  ngOnInit(): void {
  }

  memberChosed(member: any) {
    this.memberChosedEvent.emit(member);
  }
}
