<div class="my-plan-page-container">
    <div [ngStyle]="showOneOnOne ? {'padding':'3.2rem 8rem'}:{}" class="my-plan-page-header">
        <h2 *ngIf="!isPreviewMemberGoals" class="my-plan-header-title">{{!showOneOnOne ? ('my_plan_menu_title' | translate):('my_plan_one_on_one_title' | translate)}}</h2>
        <h2 *ngIf="isPreviewMemberGoals" class="my-plan-header-title">{{'my_plan_menu_title_leader_preview' | translate}}</h2>
        <button *ngIf="!showOneOnOne && !isPreviewMemberGoals" class="create-goal-btn" (click)="openGoalModal('create')" pButton label="{{'my_plan_create_new_goal_btn' | translate}}"></button>
        <button *ngIf="isPreviewMemberGoals" class="search-again-btn" (click)="goToOneOnOne()" pButton label="{{'my_plan_search_again_btn' | translate}}"></button>
        <button *ngIf="userHighPriority && !showOneOnOne && !isPreviewMemberGoals" 
            class="go-to-one-on-one-btn" 
            (click)="goToOneOnOne()" pButton label="{{'my_plan_one_on_one_btn' | translate}}"></button>
        <button *ngIf="userHighPriority && (showOneOnOne || isPreviewMemberGoals)"
            [ngStyle]="isPreviewMemberGoals ? {'margin-left':'0'}:{}" 
            class="go-to-one-on-one-btn" 
            (click)="goBackToGoalsoverview()" pButton label="{{'my_plan_one_on_one_back_btn' | translate}}"></button>
    </div>
    <div class="my-plan-page-body">
       <app-create-goal-modal *ngIf="isOpenCreateGoalModal" 
            [openGoalModal]="openGoalModalDetails"
            [goalAvailableLabels]="goalLabels"
            [goalAvailableFrequencies]="goalFrequencies" 
            (goalCreated)="createGoal($event)"
            (goalStarted)="startGoal($event)"
            (goalCompleted)="completeGoal($event)"
            (goalArchived)="archiveGoal($event)"
            (goalEdited)="editGoal($event)"
            (goalMoveToIdea)="moveGoalBackToIdea($event)"
            (saveMilestones)="addMilestone($event)"
            (updateMilestone)="updateMilestone($event)"
            (goalDeleted)="deleteGoal($event)">
        </app-create-goal-modal>
       <app-goals-overview *ngIf="!showOneOnOne && showGoalsOverview"
            [userGoals]="userGoals"
            [leaderPreviewMode]="isPreviewMemberGoals"
            [memberPreviewed]="oneOnOneMember"
            (deleteGoalEvent)="deleteGoal($event)"
            (openPreview)="openGoalPreview($event)"
            (setPrivateGoals)="setPrivateGoals($event)"></app-goals-overview>
        <app-one-on-one *ngIf="showOneOnOne"
            [oneOnOneMembers]="oneOnOneMembers"
            (searchMember)="getOneOnOneMembers($event)"
            (memberChosedEvent)="previewMemberGoals($event)"></app-one-on-one>
    </div>
</div>