import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiwrapperService } from 'src/app/services/apiwrapper.service';

@Injectable({
  providedIn: 'root'
})
export class MyPlanServiceService {

  constructor(private api: ApiwrapperService) { }

  getUserGoals(): Observable<any> {
    return this.api.getUserGoals();
  }

  getGoalsByUserUuid(userUuid: any): Observable<any> {
    return this.api.getGoalsByUserUuid(userUuid);
  }

  getGoalLabels(goalUuid: any): Observable<any> {
    return this.api.getGoalLabels(goalUuid);
  }

  getAvailableLabels(): Observable<any> {
    return this.api.getAvailableLables();
  }

  getGoalAvailableFrequencies(): Observable<any> {
    return this.api.getGoalFrequencies();
  }

  createGoal(goalBody: any): Observable<any> {
    return this.api.createGoal(goalBody);
  }

  updateGoal(goalUuid: string, goalBody: any): Observable<any> {
    return this.api.patchGoal(goalUuid, goalBody);
  }

  deleteGoal(goalUuid: any): Observable<any> {
    return this.api.deleteGoal(goalUuid);
  }

  getGoalMilestones(goalUuid: any): Observable<any> {
    return this.api.getGoalMilestones(goalUuid);
  }

  createGoalMilestone(goalMilestone: any) {
    return this.api.createGoalMilestone(goalMilestone);
  }

  updateGoalMilestone(milestoneUuid: any, milestoneBody: any): Observable<any> {
    return this.api.patchMilestone(milestoneUuid, milestoneBody);
  }

  getOneOnOneMembers(searchValue: any): Observable<any> {
    return this.api.getOneOnOneMembers(searchValue);
  }

}
