<div *ngIf="showGoals" class="goals-overview-main-container">
    <div class="goals-overview-header">
        <h2 *ngIf="!isLeaderPreviewMode" class="goals-overview-container-title">{{userHighPriority ?('my_plan_goals_overview_title_leader' | translate):('my_plan_goals_overview_title' | translate)}}</h2>
        <div *ngIf="isLeaderPreviewMode" class="member-previewed-container">
            <img class="member-avatar" [src]="(member?.avatar?.path !== null) ? this.data.baseUrl+member.avatar.path : '../../../assets/svg/user-icon.svg'">
            <div class="member-details">
                <span class="member-name">{{member.detail.firstname+' '+member.detail.lastname}}</span>
                <span class="member-email">{{member.email}}</span>
            </div>
        </div>
        <button *ngIf="!isLeaderPreviewMode" [ngClass]="isPrivate ? 'active-btn':''" class="visibility-btn" pButton (click)="setPrivateGoalsOn()" label="{{'my_plan_goals_overview_private_btn' | translate}}">
            <img src="../../../../../assets/svg/goal_visibility_off.svg">   
        </button>
        <button *ngIf="!isLeaderPreviewMode" [ngClass]="isVisible ? 'active-btn':''" class="visibility-btn" pButton (click)="setPrivateGoalsOff()" label="{{'my_plan_goals_overview_visible_btn' | translate}}">
            <img src="../../../../../assets/svg/goal_visibility.svg">
        </button>
        <button *ngIf="!isLeaderPreviewMode" class="visibility-btn archived" pButton (click)="seeArchived()" [label]="showArchivedList ? ('my_plan_goals_overview_hide_archive_btn' | translate):('my_plan_goals_overview_see_archive_btn' | translate)">
        </button>
    </div>
    <div class="spinner-border text-primary categ-statement-spinner" role="status" *ngIf="showLoading"></div>
    <div *ngIf="!showLoading" class="goals-overview-container-body">
        <div class="goals-row ideas"
            cdkDropList
            id="todoList"
            #todoList="cdkDropList"
            [cdkDropListData]="goalsToDo"
            [cdkDropListConnectedTo]="[progressList, doneList]"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="dragOverSetter($event)">
            <span class="goals-row-title ideas">{{'my_plan_goals_overview_ideas' | translate}}</span>
            <div *ngIf="!goalsToDo.length && !dragOverToDo" class="empty-goal-row ideas">
                <span class="empty-goal-row-text ideas">{{isLeaderPreviewMode ? ('my_plan_goals_overview_empty_row_leader_prev' | translate):('my_plan_goals_overview_empty_row' | translate)}}</span>
            </div>
            <div (click)="openModalInPreview(ideaGoal)" [cdkDragDisabled]="isLeaderPreviewMode" class="goal-container" *ngFor="let ideaGoal of goalsToDo" cdkDrag>
                <div class="goal-container-header">
                    <!-- <h2 (click)="deleteGoal(ideaGoal);$event.stopPropagation()">DELETE</h2> -->
                    <h2 class="goal-title">{{ideaGoal.title}}</h2>
                    <img [src]="ideaGoal.isVisible ? '../../../../../assets/svg/goal_visibility.svg':'../../../../../assets/svg/goal_visibility_off.svg'">
                </div>
                <div class="goal-labels-container">
                    <div class="goal-label" [ngStyle]="index <= 1 ? {'display':'flex'}:{'display':'none'}" [style]="{'background-color' : label.label.backgroundColor}" *ngFor="let label of ideaGoal.labels, let index = index">
                        <span [ngStyle]="{'color': label.label.textColor}">{{label.label.title}}</span>
                    </div>
                    <span *ngIf="ideaGoal.labels?.length > 2" style="font-size: 1.2rem;font-weight: 500;">{{'+'+(ideaGoal.labels.length - 2)}}</span>
                </div>
            </div>
        </div>
        <div class="goals-row in-progress"
            cdkDropList
            id="progressList"
            #progressList="cdkDropList"
            [cdkDropListData]="goalsInProgress"
            [cdkDropListConnectedTo]="[todoList, doneList]"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="dragOverSetter($event)">
            <span class="goals-row-title in-progress">{{'my_plan_goals_overview_in_progress_header' | translate}}</span>
            <div *ngIf="!goalsInProgress.length && !dragOverProgress" class="empty-goal-row in-progress">
                <span class="empty-goal-row-text in-progress">{{isLeaderPreviewMode ? ('my_plan_goals_overview_empty_row_leader_prev' | translate):('my_plan_goals_overview_empty_row' | translate)}}</span>
            </div>
            <div (click)="openModalInPreview(progressGoal)" [cdkDragDisabled]="isLeaderPreviewMode" class="goal-container" *ngFor="let progressGoal of goalsInProgress" cdkDrag>
                <div class="goal-container-header">
                    <!-- <h2 (click)="deleteGoal(progressGoal);$event.stopPropagation()">DELETE</h2> -->
                    <h2 class="goal-title">{{progressGoal.title}}</h2>
                    <img [src]="progressGoal.isVisible ? '../../../../../assets/svg/goal_visibility.svg':'../../../../../assets/svg/goal_visibility_off.svg'">
                </div>
                <div class="goal-labels-container">
                    <div class="goal-label" [ngStyle]="index <= 1 ? {'display':'flex'}:{'display':'none'}" [style]="{'background-color' : label.label.backgroundColor}" *ngFor="let label of progressGoal.labels, let index = index">
                        <span [ngStyle]="{'color': label.label.textColor}">{{label.label.title}}</span>
                    </div>
                    <span *ngIf="progressGoal.labels?.length > 2" style="font-size: 1.2rem;font-weight: 500;">{{'+'+(progressGoal.labels.length - 2)}}</span>
                </div>
                <div class="dates-and-freq-labels-container">
                    <div >
                        <div *ngIf="progressGoal.startDate && (progressGoal.startDateToCompare > currentDate)" class="goal-due-date-container">
                            <span>{{('my_plan_goal_modal_preview_start_in' | translate:{date: progressGoal.startDate.date | date:'dd MMM yyyy'})}}</span>
                        </div>
                        <div *ngIf="progressGoal.dueDate && !(progressGoal?.startDateToCompare > currentDate)" [ngClass]="currentDate > progressGoal.dueDateToCompare ? 'passed-date':''" class="goal-due-date-container">
                             <span>{{('my_plan_goal_modal_preview_deadline' | translate:{date: progressGoal.dueDate.date | date:'dd MMM yyyy'})}}</span>
                        </div>
                    </div>
                    <div *ngIf="progressGoal.frequency && (progressGoal.startDate || progressGoal.frequency.slug === 'daily') && progressGoal.status !== 'to_do'" class="goal-preview-frequencie-con">
                        <span>{{progressGoal.frequency.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="goals-row done"
            cdkDropList
            id="doneList"
            #doneList="cdkDropList"
            [cdkDropListData]="goalsDone"
            [cdkDropListConnectedTo]="[todoList, progressList, archiveList]"
            cdkDropListSortingDisabled
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="dragOverSetter($event)">
            <span class="goals-row-title done">{{'my_plan_goals_overview_completed' | translate}}</span>
            <div *ngIf="!goalsDone.length && !dragOverDone" class="empty-goal-row done">
                <span class="empty-goal-row-text done">{{isLeaderPreviewMode ? ('my_plan_goals_overview_empty_row_leader_prev' | translate):('my_plan_goals_overview_empty_row' | translate)}}</span>
            </div>
            <div (click)="openModalInPreview(doneGoal)" [cdkDragDisabled]="isLeaderPreviewMode" class="goal-container" *ngFor="let doneGoal of goalsDone" cdkDrag>
                <div class="goal-container-header">
                    <!-- <h2 (click)="deleteGoal(doneGoal);$event.stopPropagation()">DELETE</h2> -->
                    <h2 class="goal-title">{{doneGoal.title}}</h2>
                    <img [src]="doneGoal.isVisible ? '../../../../../assets/svg/goal_visibility.svg':'../../../../../assets/svg/goal_visibility_off.svg'">
                </div>
                <div class="goal-labels-container">
                    <div class="goal-label" [ngStyle]="index <= 1 ? {'display':'flex'}:{'display':'none'}" [style]="{'background-color' : label.label.backgroundColor}" *ngFor="let label of doneGoal.labels, let index = index">
                        <span [ngStyle]="{'color': label.label.textColor}">{{label.label.title}}</span>
                    </div>
                    <span *ngIf="doneGoal.labels?.length > 2" style="font-size: 1.2rem;font-weight: 500;">{{'+'+(doneGoal.labels.length - 2)}}</span>
                </div>
                <div class="dates-and-freq-labels-container">
                    <div *ngIf="doneGoal.completionDate" class="goal-due-date-container">
                        <span>{{('my_plan_goal_modal_preview_deadline_completed' | translate:{date: doneGoal.completionDate.created.date | date:'dd MMM yyyy'})}}</span>
                    </div>
                    <div *ngIf="doneGoal.frequency && doneGoal.startDate && doneGoal.status !== 'to_do'" class="goal-preview-frequencie-con">
                        <span>{{doneGoal.frequency.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div [ngStyle]="showArchivedList ? {'display':'flex'}:{'display':'none'}"
            class="goals-row archived"
            cdkDropList
            id="archiveList"
            #archiveList="cdkDropList"
            [cdkDropListData]="goalsArchived"
            [cdkDropListConnectedTo]="[]"
            (cdkDropListDropped)="drop($event)"
            (cdkDropListEntered)="dragOverSetter($event)">
            <span class="goals-row-title archived">{{'team_actions_archived' | translate}}</span>
            <div *ngIf="!goalsArchived.length && !dragOverDone" class="empty-goal-row archived">
                <span class="empty-goal-row-text archived">{{isLeaderPreviewMode ? ('my_plan_goals_overview_empty_row_leader_prev' | translate):('my_plan_goals_overview_empty_row' | translate)}}</span>
            </div>
            <div class="goal-container" [cdkDragDisabled]="isLeaderPreviewMode" *ngFor="let archivedGoal of goalsArchived" cdkDrag>
                <div class="goal-container-header">
                    <!-- <h2 (click)="deleteGoal(doneGoal);$event.stopPropagation()">DELETE</h2> -->
                    <h2 class="goal-title">{{archivedGoal.title}}</h2>
                    <img [src]="archivedGoal.isVisible ? '../../../../../assets/svg/goal_visibility.svg':'../../../../../assets/svg/goal_visibility_off.svg'">
                </div>
                <div class="goal-labels-container">
                    <div class="goal-label" [ngStyle]="index <= 1 ? {'display':'flex'}:{'display':'none'}" [style]="{'background-color' : label.label.backgroundColor}" *ngFor="let label of archivedGoal.labels, let index = index">
                        <span [ngStyle]="{'color': label.label.textColor}">{{label.label.title}}</span>
                    </div>
                    <span *ngIf="archivedGoal.labels?.length > 2" style="font-size: 1.2rem;font-weight: 500;">{{'+'+(archivedGoal.labels.length - 2)}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
